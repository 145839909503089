import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import CookieConsent from 'react-cookie-consent';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actions as cookieActions } from '../state/actions/cookie';

let CookieConsentMessage = ({
    intl,
    setCookieConsent,
    acceptedCookie,
    getCookieConsent,
}) => {
    useEffect(() => {
        getCookieConsent();
    }, []);
    return (
        <CookieConsent
            style={{ background: '#be2045', alignItems: 'center' }}
            buttonStyle={{
                background: '#35c2db',
                color: '#ffffff',
                fontWeight: '700',
                borderRadius: '5px',
            }}
            visible={acceptedCookie ? 'hidden' : 'byCookieValue'}
            expires={365}
            buttonText={intl.formatMessage({
                id: 'cookie_consent_button',
                defaultMessage: 'I Undestand',
            })}
            onAccept={() => {
                setCookieConsent();
            }}
        >
            <div>
                {intl.formatMessage({
                    id: 'cookie_consent',
                    defaultMessage:
                        'MenuDelite uses Cookies to enhance your experience. By continuing to browse our website, or clicking "I Understand" you agree to the storing of cookies on your device. To learn more about how we use cookies, please visit our',
                })}{' '}
                <a href="/privacy-policy">
                    {intl.formatMessage({
                        id: 'cookie_consent_link',
                        defaultMessage: 'Cookies Policy',
                    })}
                </a>
                .
            </div>
        </CookieConsent>
    );
};

CookieConsentMessage = connect(
    (state, props) => ({
        acceptedCookie: _.get(state, ['cookie', 'cookieConsent'], false),
    }),
    { ...cookieActions }
)(CookieConsentMessage);

export default injectIntl(CookieConsentMessage);
