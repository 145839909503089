import countryCodeEmoji from 'country-code-emoji';
import { countries } from 'countries-list';
import {
    CREATE_SECTION_SUCCESSFUL,
    CREATE_SECTION_FAILED,
    FETCHED_SECTIONS,
    ERROR_FETCHING_SECTIONS,
    ADD_MENU_ITEM_SUCCESSFUL,
    EDIT_MENU_ITEM_SUCCESSFUL,
    ERROR_EDITING_MENU_ITEM,
    ERROR_ADDING_MENU_ITEM,
    DELETE_SECTION_SUCCESSFUL,
    DELETE_SECTION_FAILED,
    DELETE_ITEM_SUCCESSFUL,
    DELETE_ITEM_FAILED,
    TOGGLE_ITEM_AVAILABILITY_SUCCESSFUL,
    TOGGLE_ITEM_AVAILABILITY_FAILED,
    TOGGLE_SECTION_AVAILABILITY,
    TOGGLE_SECTION_AVAILABILITY_SUCCESSFUL,
    TOGGLE_SECTION_AVAILABILITY_FAILED,
    UPDATE_MENU_ITEM_ORDER_SUCCESSFUL,
    UPDATE_SECTION_ORDER_SUCCESSFUL,
    UPDATE_IS_DRINKS_SUCCESSFUL,
    REMOVE_MODIFIER_FROM_ITEM_SUCCESSFUL,
    ADD_MODIFIER_TO_ITEM_SUCCESSFUL,
    REMOVE_TAG_FROM_ITEM_SUCCESSFUL,
    ADD_TAG_TO_ITEM_SUCCESSFUL,
    REMOVE_ALLERGEN_FROM_ITEM_SUCCESSFUL,
    ADD_ALLERGEN_TO_ITEM_SUCCESSFUL,
    EDIT_SECTION_SUCCESSFUL,
    ADD_RECOMMENDED_ITEM_TO_ITEM_SUCCESSFUL,
    REMOVE_RECOMMENDED_ITEM_FROM_ITEM_SUCCESSFUL,
    GENERATE_AI_MENU_DESCRIPTION_SUCCESSFUL,
    EDIT_TAG_SUCCESSFUL,
    DELETE_TAG_SUCCESSFUL,
    CLONE_SECTION_SUCCESSFUL,
    CLONE_SECTION_ITEM_SUCCESSFUL,
} from '../types';
import { getOperatingSystem } from '../../utils/util';

const initialState = {};

const sectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MENU_ITEM_SUCCESSFUL:
            const section = state.sections.find(
                section => section.id === action.item.sectionId
            );
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        section => section.id !== action.item.sectionId
                    ),
                    {
                        ...section,
                        aiDescription: null,
                        menus: [
                            ...section.menus,
                            {
                                ...action.item,
                                tagMenus: [],
                                modifierGroupsMenus: [],
                                allergenMenus: [],
                                recommendedItems: [],
                            },
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case EDIT_TAG_SUCCESSFUL:
            const tagId = parseInt(action.data.id, 10);
            const { color, name } = action.data.changes;
            return {
                ...state,
                sections: state.sections.map(s => ({
                    ...s,
                    tagMenus: (s.tagMenus || []).map(tm => ({
                        ...tm,
                        tag:
                            tm.tag.id === tagId
                                ? {
                                      ...tm.tag,
                                      name,
                                      color,
                                  }
                                : tm.tag,
                    })),
                })),
            };
        case DELETE_TAG_SUCCESSFUL:
            const tagId2 = parseInt(action.data.tagId, 10);
            return {
                ...state,
                sections: state.sections.map(s => ({
                    ...s,
                    tagMenus: (s.tagMenus || []).filter(
                        tm => tm.tag.id !== tagId2
                    ),
                })),
            };
        case ERROR_ADDING_MENU_ITEM:
            // fix this
            return {
                ...state,
                createSectionError: action.error,
            };
        case GENERATE_AI_MENU_DESCRIPTION_SUCCESSFUL:
            const section4 = state.sections.find(
                section => section.id === action.sectionId
            );
            const item2 = section4.menus.find(
                menu => menu.id === action.itemId
            );
            if (!action.itemId) {
                return {
                    ...state,
                    sections: [
                        ...state.sections.filter(
                            section => section.id !== action.sectionId
                        ),
                        {
                            ...section4,
                            aiDescription: action.description,
                        },
                    ].sort((a, b) => {
                        if (a.positionNew === null && b.positionNew === null) {
                            return a.createdAt > b.createdAt ? 1 : -1;
                        }
                        if (a.positionNew === null) {
                            return 1;
                        }
                        if (b.positionNew === null) {
                            return -1;
                        }
                        if (
                            parseInt(a.positionNew, 10) >
                            parseInt(b.positionNew, 10)
                        ) {
                            return 1;
                        }
                        return -1;
                    }),
                };
            }
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        section => section.id !== action.sectionId
                    ),
                    {
                        ...section4,
                        aiDescription: null,
                        menus: [
                            ...section4.menus.filter(
                                item => item.id !== action.itemId
                            ),
                            { ...item2, aiDescription: action.description },
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case EDIT_MENU_ITEM_SUCCESSFUL:
        case TOGGLE_ITEM_AVAILABILITY_SUCCESSFUL:
            const section2 = state.sections.find(
                section => section.id === action.item.sectionId
            );
            const item = section2.menus.find(
                menu => menu.id === action.item.itemId
            );
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        section => section.id !== action.item.sectionId
                    ),
                    {
                        ...section2,
                        aiDescription: null,
                        menus: [
                            ...section2.menus.filter(
                                item => item.id !== action.item.itemId
                            ),
                            {
                                ...item,
                                ...action.item.changes,
                                aiDescription: null,
                            },
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case UPDATE_IS_DRINKS_SUCCESSFUL:
            const section5 = state.sections.find(
                section => section.id === action.sectionId
            );
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        section => section.id !== action.sectionId
                    ),
                    {
                        ...section5,
                        isDrinks: action.isDrinks,
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case UPDATE_SECTION_ORDER_SUCCESSFUL:
            const sections = state.sections
                .map(i => ({
                    ...i,
                    positionNew: action.itemOrdering.find(
                        orderedItem => orderedItem.id === i.id
                    ).position,
                }))
                .sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                });
            return {
                ...state,
                sections,
            };
        case UPDATE_MENU_ITEM_ORDER_SUCCESSFUL:
            const section3 = state.sections.find(
                section => section.id === action.sectionId
            );
            const section3Menus = section3.menus
                .map(i => ({
                    ...i,
                    positionNew: action.itemOrdering.find(
                        orderedItem => orderedItem.id === i.id
                    ).position,
                }))
                .sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                });
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        section => section.id !== action.sectionId
                    ),
                    {
                        ...section3,
                        menus: section3Menus,
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case DELETE_SECTION_SUCCESSFUL:
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        s => s.id !== parseInt(action.data.sectionId, 10)
                    ),
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case DELETE_SECTION_FAILED:
            return { ...state };
        case DELETE_ITEM_SUCCESSFUL:
            const s = state.sections.find(
                s => s.id === parseInt(action.data.sectionId, 10)
            );
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        s => s.id !== parseInt(action.data.sectionId, 10)
                    ),
                    {
                        ...s,

                        menus: [
                            ...s.menus.filter(
                                i => i.id !== parseInt(action.data.itemId, 10)
                            ),
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case DELETE_ITEM_FAILED:
            return { ...state };
        case ERROR_EDITING_MENU_ITEM:
            return { ...state };
        case TOGGLE_ITEM_AVAILABILITY_FAILED:
            return { ...state };
        case CREATE_SECTION_SUCCESSFUL:
            return {
                ...state,
                sections: [
                    ...state.sections,
                    { ...action.data.section, menus: [], tagMenus: [] },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case EDIT_SECTION_SUCCESSFUL:
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        g => g.id !== parseInt(action.data.id, 10)
                    ),
                    {
                        ...state.sections.find(
                            g => g.id === parseInt(action.data.id, 10)
                        ),
                        id: parseInt(action.data.id, 10),
                        title: action.data.changes.title,
                        description: action.data.changes.description,
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case CLONE_SECTION_SUCCESSFUL:
            return {
                ...state,
                sections: [
                    ...state.sections,
                    {
                        ...action.item.section,
                        aiDescription: null,
                        menus: (action.item.createdMenuItems
                            ? [
                                  ...action.item.createdMenuItems.map(cmi => ({
                                      ...cmi,
                                      tagMenus: [],
                                      modifierGroupsMenus: [],
                                      allergenMenus: [],
                                      recommendedItems: [],
                                  })),
                              ]
                            : []
                        ).sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case CLONE_SECTION_ITEM_SUCCESSFUL:
            const section99 = state.sections.find(
                section => section.id === action.item.sectionId
            );
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        section => section.id !== action.item.sectionId
                    ),
                    {
                        ...section99,
                        aiDescription: null,
                        menus: [
                            ...section99.menus,
                            {
                                ...action.item,
                                tagMenus: [],
                                modifierGroupsMenus: [],
                                allergenMenus: [],
                                recommendedItems: [],
                            },
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case TOGGLE_SECTION_AVAILABILITY_SUCCESSFUL:
            return {
                ...state,
                sections: [
                    ...state.sections.filter(
                        g => g.id !== parseInt(action.item.sectionId, 10)
                    ),
                    {
                        ...state.sections.find(
                            g => g.id === parseInt(action.item.sectionId, 10)
                        ),
                        id: parseInt(action.item.sectionId, 10),
                        isAvailable: action.item.changes.isAvailable,
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case CREATE_SECTION_FAILED:
            return {
                ...state,
                createSectionError: action.error,
            };
        case FETCHED_SECTIONS:
            return {
                ...state,
                sections: action.sections.sortedSections.sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
                sectionLanguages: action.sections.languages.map(lan => ({
                    key: lan,
                    text: `${
                        getOperatingSystem(window) !== 'Windows'
                            ? countryCodeEmoji(
                                  lan === 'en'
                                      ? 'gb'
                                      : lan === 'es'
                                      ? 'es'
                                      : lan === 'pt'
                                      ? 'pt'
                                      : lan === 'fr'
                                      ? 'fr'
                                      : Object.keys(
                                            countries
                                        ).find(countryCode =>
                                            countries[
                                                countryCode
                                            ].languages.includes(lan)
                                        ) || 'gb'
                              )
                            : ''
                    } ${lan.toUpperCase()}`,
                    value: lan,
                })),
            };
        case ERROR_FETCHING_SECTIONS:
            return {
                ...state,
                error: action.error,
            };
        case REMOVE_MODIFIER_FROM_ITEM_SUCCESSFUL:
            return {
                ...state,
                deletedModifier: [
                    ...(state.deletedModifier || []),
                    action.data.id,
                ],
            };
        case REMOVE_RECOMMENDED_ITEM_FROM_ITEM_SUCCESSFUL:
            return {
                ...state,
                deletedRecommendedItems: [
                    ...(state.deletedRecommendedItems || []),
                    action.data.id,
                ],
            };
        case REMOVE_TAG_FROM_ITEM_SUCCESSFUL:
            return {
                ...state,
                deletedTag: [...(state.deletedTag || []), action.data.id],
            };
        case REMOVE_ALLERGEN_FROM_ITEM_SUCCESSFUL:
            return {
                ...state,
                deletedAllergens: action.data.isDescriptor
                    ? state.deletedAllergens
                    : [...(state.deletedAllergens || []), action.data.id],
                deletedDescriptors: action.data.isDescriptor
                    ? [...(state.deletedDescriptors || []), action.data.id]
                    : state.deletedDescriptors,
            };
        case ADD_ALLERGEN_TO_ITEM_SUCCESSFUL:
            const section9 = state.sections.find(
                s => s.id === action.data.sectionId
            );
            const menuItem8 = section9.menus.find(
                m => m.id === action.data.menuId
            );

            return {
                ...state,
                sections: [
                    ...state.sections.filter(s => s.id !== section9.id),
                    {
                        ...section9,
                        menus: [
                            ...section9.menus.filter(
                                m => m.id !== menuItem8.id
                            ),
                            {
                                ...menuItem8,
                                allergenMenus: [
                                    ...menuItem8.allergenMenus,
                                    {
                                        menuId: action.data.menuId,
                                        allergenId: action.data.allergenId,
                                        id: action.data.id,
                                        sectionId: action.data.sectionId,
                                    },
                                ],
                            },
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };

        case ADD_MODIFIER_TO_ITEM_SUCCESSFUL:
            const section7 = state.sections.find(
                s => s.id === action.data.sectionId
            );
            const menuItem = section7.menus.find(
                m => m.id === action.data.menuId
            );

            return {
                ...state,
                sections: [
                    ...state.sections.filter(s => s.id !== section7.id),
                    {
                        ...section7,
                        menus: [
                            ...section7.menus.filter(m => m.id !== menuItem.id),
                            {
                                ...menuItem,
                                modifierGroupsMenus: [
                                    ...menuItem.modifierGroupsMenus,
                                    {
                                        menuId: action.data.menuId,
                                        modifierGroupId:
                                            action.data.modifierGroupId,
                                        id: action.data.id,
                                        sectionId: action.data.sectionId,
                                    },
                                ],
                            },
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case ADD_RECOMMENDED_ITEM_TO_ITEM_SUCCESSFUL:
            const section10 = state.sections.find(
                s => s.id === action.data.sectionId
            );
            const menuItem3 = section10.menus.find(
                m => m.id === action.data.menuId
            );

            return {
                ...state,
                sections: [
                    ...state.sections.filter(s => s.id !== section10.id),
                    {
                        ...section10,
                        menus: [
                            ...section10.menus.filter(
                                m => m.id !== menuItem3.id
                            ),
                            {
                                ...menuItem3,
                                recommendedItems: [
                                    ...menuItem3.recommendedItems,
                                    {
                                        menuId: action.data.menuId,
                                        recommendedItemId:
                                            action.data.recommendedItemId,
                                        id: action.data.id,
                                        sectionId: action.data.sectionId,
                                    },
                                ],
                            },
                        ].sort((a, b) => {
                            if (
                                a.positionNew === null &&
                                b.positionNew === null
                            ) {
                                return a.createdAt > b.createdAt ? 1 : -1;
                            }
                            if (a.positionNew === null) {
                                return 1;
                            }
                            if (b.positionNew === null) {
                                return -1;
                            }
                            if (
                                parseInt(a.positionNew, 10) >
                                parseInt(b.positionNew, 10)
                            ) {
                                return 1;
                            }
                            return -1;
                        }),
                    },
                ].sort((a, b) => {
                    if (a.positionNew === null && b.positionNew === null) {
                        return a.createdAt > b.createdAt ? 1 : -1;
                    }
                    if (a.positionNew === null) {
                        return 1;
                    }
                    if (b.positionNew === null) {
                        return -1;
                    }
                    if (
                        parseInt(a.positionNew, 10) >
                        parseInt(b.positionNew, 10)
                    ) {
                        return 1;
                    }
                    return -1;
                }),
            };
        case ADD_TAG_TO_ITEM_SUCCESSFUL:
            const section8 = state.sections.find(
                s => s.id === action.data.sectionId
            );
            const menuItem2 = section8.menus.find(
                m => m.id === action.data.menuId
            );

            return action.data.isSection
                ? {
                      ...state,
                      sections: [
                          ...state.sections.filter(s => s.id !== section8.id),
                          {
                              ...section8,
                              tagMenus: [
                                  ...(section8.tagMenus || []),
                                  {
                                      id: action.data.id,
                                      isDeleted: false,
                                      menuId: action.data.menuId,
                                      tagId: action.data.tagId,
                                      sectionId: action.data.sectionId,
                                      tag: action.data.tag,
                                  },
                              ],
                          },
                      ].sort((a, b) => {
                          if (
                              a.positionNew === null &&
                              b.positionNew === null
                          ) {
                              return a.createdAt > b.createdAt ? 1 : -1;
                          }
                          if (a.positionNew === null) {
                              return 1;
                          }
                          if (b.positionNew === null) {
                              return -1;
                          }
                          if (
                              parseInt(a.positionNew, 10) >
                              parseInt(b.positionNew, 10)
                          ) {
                              return 1;
                          }
                          return -1;
                      }),
                  }
                : {
                      ...state,
                      sections: [
                          ...state.sections.filter(s => s.id !== section8.id),
                          {
                              ...section8,
                              menus: [
                                  ...section8.menus.filter(
                                      m => m.id !== menuItem2.id
                                  ),
                                  {
                                      ...menuItem2,
                                      tagMenus: [
                                          ...menuItem2.tagMenus,
                                          {
                                              id: action.data.id,
                                              isDeleted: false,
                                              menuId: action.data.menuId,
                                              tagId: action.data.tagId,
                                              sectionId: action.data.sectionId,
                                              tag: action.data.tag,
                                          },
                                      ],
                                  },
                              ].sort((a, b) => {
                                  if (
                                      a.positionNew === null &&
                                      b.positionNew === null
                                  ) {
                                      return a.createdAt > b.createdAt ? 1 : -1;
                                  }
                                  if (a.positionNew === null) {
                                      return 1;
                                  }
                                  if (b.positionNew === null) {
                                      return -1;
                                  }
                                  if (
                                      parseInt(a.positionNew, 10) >
                                      parseInt(b.positionNew, 10)
                                  ) {
                                      return 1;
                                  }
                                  return -1;
                              }),
                          },
                      ].sort((a, b) => {
                          if (
                              a.positionNew === null &&
                              b.positionNew === null
                          ) {
                              return a.createdAt > b.createdAt ? 1 : -1;
                          }
                          if (a.positionNew === null) {
                              return 1;
                          }
                          if (b.positionNew === null) {
                              return -1;
                          }
                          if (
                              parseInt(a.positionNew, 10) >
                              parseInt(b.positionNew, 10)
                          ) {
                              return 1;
                          }
                          return -1;
                      }),
                  };

        default:
            return state;
    }
};

export default sectionReducer;
