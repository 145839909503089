import { io } from 'socket.io-client';
import { getToken } from './tokenFetcher';
import store from './store';
import { SOCKET_EVENTS } from '../state/types';
import { updateSocketTokens } from '../state/actions/auth';

const socket = io(`${process.env.REACT_APP_API_URL}/`, {
    query: `Authorization=${getToken(store.getState())}&isRestaurant=true`,
    transports: ['websocket'],
    upgrade: false,
});

socket.on(SOCKET_EVENTS.NEW_ACCESS_TOKEN, params => {
    const { token, tokenExpiry, refreshToken } = params;
    updateSocketTokens({
        accesstoken: token,
        accesstokenxpiry: tokenExpiry,
        refreshtoken: refreshToken,
    });
});

export default socket;
