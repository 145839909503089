import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { css } from 'styled-components/macro'; //eslint-disable-line

const Container = styled.div`
    ${tw`relative bg-gray-200 -mx-8 -mb-8 px-8`};
    padding-left: 4rem;
    padding-right: 4rem;
`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
    Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
    ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
    svg {
        ${tw`w-4 h-4`}
    }
`;

const StyledPowered = styled.div`
    color: #be2045;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

export default ({ small }) => {
    const intl = useIntl();
    if (small)
        return (
            <StyledPowered>
                <a href="/">Powered by MenuDelite</a>
                <a
                    href="/privacy-policy"
                    style={{
                        color: 'black',
                        fontWeight: 'normal',
                        fontSize: '12px',
                    }}
                >
                    <b>Privacy Policy</b>
                </a>
            </StyledPowered>
        );
    return (
        <Container>
            <LogoText style={{ paddingTop: '50px' }}>
                MenuDelite by HotTable App Ltd.
                <CopywrightNotice style={{ marginTop: '10px' }}>
                    &copy;
                    {intl.formatMessage({
                        id: 'copyright_line',
                        defaultMessage:
                            '2024 HotTable App Ltd. All Rights Reserved.',
                    })}
                </CopywrightNotice>
            </LogoText>
            <FiveColumns>
                <Column style={{ width: '50%' }}>
                    <ColumnHeading>
                        {intl.formatMessage({
                            id: 'application',
                            defaultMessage: 'Application',
                        })}
                    </ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="/signin">
                                {intl.formatMessage({
                                    id: 'log_in',
                                    defaultMessage: 'Log In',
                                })}
                            </Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/signup">
                                {intl.formatMessage({
                                    id: 'sign_up',
                                    defaultMessage: 'Sign Up',
                                })}
                            </Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/businesses">
                                {intl.formatMessage({
                                    id: 'join_us',
                                    defaultMessage: 'Join Us',
                                })}
                            </Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/pricing">
                                {intl.formatMessage({
                                    id: 'pricing',
                                    defaultMessage: 'Pricing',
                                })}
                            </Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/customers">
                                {intl.formatMessage({
                                    id: 'browse_restaurants',
                                    defaultMessage: 'For Customers',
                                })}
                            </Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
                <Column style={{ width: '50%' }}>
                    <ColumnHeading>
                        {intl.formatMessage({
                            id: 'legal',
                            defaultMessage: 'Legal',
                        })}
                    </ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="/privacy-policy">
                                {intl.formatMessage({
                                    id: 'privacy_policy',
                                    defaultMessage: 'Privacy & Cookie Policy',
                                })}
                            </Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/terms-of-service">
                                {intl.formatMessage({
                                    id: 'terms_of_service',
                                    defaultMessage: 'Terms of Service',
                                })}
                            </Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
            </FiveColumns>
        </Container>
    );
};
