import moment from 'moment';
import {
    FETCHED_BOOKINGS,
    ERROR_FETCHING_BOOKINGS,
    CREATE_BOOKING,
    CREATE_BOOKING_SUCCESSFUL,
    ERROR_CREATING_BOOKING,
    LOADING,
    FETCH_BOOKING_SUCCESSFUL,
    FETCH_OCCUPANCIES_SUCCESSFUL,
} from '../types';

const initialState = {};

const bookingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            if (action.key === CREATE_BOOKING) {
                return {
                    ...state,
                    createBookingSuccessful: null,
                };
            }
            return state;
        case FETCH_OCCUPANCIES_SUCCESSFUL:
            return {
                ...state,
                occupancies: action.data,
            };
        case FETCHED_BOOKINGS:
            return {
                ...state,
                bookings: action.items.filter(b =>
                    moment(`${action.date} ${b}`, 'DD/MM/YYYY HH:mm').isAfter(
                        new Date()
                    )
                ),
                fetchBookingsSuccess: true,
                error: null,
            };
        case ERROR_FETCHING_BOOKINGS:
            return {
                ...state,
                error: action.error,
                fetchBookingsSuccess: false,
            };
        case CREATE_BOOKING_SUCCESSFUL:
            return {
                ...state,
                createBookingSuccessful: true,
                errorCreatingBooking: false,
                successfulBooking: action.item,
            };
        case ERROR_CREATING_BOOKING:
            return {
                ...state,
                error: action.error,
                errorCreatingBooking: true,
                createBookingSuccessful: false,
            };
        case FETCH_BOOKING_SUCCESSFUL:
            return {
                ...state,
                currentBooking: action.data,
            };
        default:
            return state;
    }
};

export default bookingsReducer;
